
.MuiDrawer-root {
    .MuiDrawer-paper {
        top: 64px !important;
        height: calc(100% - 64px) !important;
        width: 256px;
        transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    
        .user-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 16px;
    
            .MuiAvatar-root {
                height: 58px;
                width: 58px;
                background-color: blueviolet;
            }

            .username {
                text-align: center;
                line-height: 1.25rem;
                margin: 10px 0;
            }
        }
    
        .drawer-container {
            padding: 0 16px;
        }
    
        @media screen and (max-width: 768px) {
            top: 0 !important;
            height: 100% !important;
            width: 70%;
        }
    }

    &.web-closed {
        .MuiDrawer-paper {
            width: 90px;
        }

    }
}
