.search-container {
    margin-bottom: 20px;

    .report-form {
        .MuiFormControl-root {
            margin: 10px;
        }

        .react-daterange-picker {
            .react-daterange-picker__wrapper {
              margin-top: 8px;
              padding: 6px;
              border-radius: 20px;
            }
      
            .react-calendar {
              position: fixed;
            }
          }
    }
}
