.login {
    background-color: #1b224d;

    .login-card {
        position: absolute;
        top: 15%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 460px;

        .app-logo {
            display: flex;
            justify-content: center;

            img {
                width: 60%;
            }
        }

        .card-views {
            .tabpanel {
                .MuiBox-root {
                    padding: 24px 10px;
                }
            }
        }

        .MuiCardActions-root {
            background-color: #cccccc;
            justify-content: center;
        }

        @media screen and (max-width: 768px) {
            width: 90vw;
        }
    }

    #tsparticles {
        height: 100vh;
    }
}