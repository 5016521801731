.search-container {
  margin-bottom: 20px;

  .report-form {
      .MuiFormControl-root {
          margin: 10px;
      }

      .select-control {
          min-width: 15%;
          
          @media screen and (max-width: 768px) {
              min-width: 40%;
          }

          .MuiInputLabel-formControl {
              top: -8px;
              left: 14px;
          }
      }
  }
}
