.update-report {
  .report-form {
    .select-control {
      margin: 10px 20px;
    }

    .react-daterange-picker {
      .react-daterange-picker__wrapper {
        padding: 6px;
        border-radius: 20px;
      }

      .react-calendar {
        position: fixed;
      }
    }
  }
}
