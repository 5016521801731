body {
    margin: 0;
    min-height: 100vh;
    height: 100%;
    background-color: rgb(244, 246, 248);
}

#root {
    .app {
        margin-left: 257px;
        background-color: rgb(244, 246, 248);
        padding: 24px;
        transition: 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

        @media screen and (max-width: 768px) {
            margin-left: 0;
            padding: 15px;
        }
    
        .MuiCardContent-root:last-child {
            padding-bottom: 16px;
        }

        &.drawer-web-closed {
            @media screen and (min-width: 768px) {
                margin-left: 90px;
            }
        }
    }

    .button-group {
        display: flex;
        justify-content: center;

        button {
            background-color: #56ccf2 !important;
            color: #FFFFFF;
            border-radius: 20px;
        }

        &.end {
            justify-content: flex-end;
            margin-top: 0;
        }
    }
}