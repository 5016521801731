.integration-container {
  padding: 12px;

  .MuiPaper-root {
    display: inline-flex;
    flex-direction: column;
    padding: 12px 20px;

    .MuiCardHeader-root {
      span {
        font-size: 1.6rem;
        font-weight: 500;
      }
    }

    .providers {
      padding: 20px;

      .selectable-provider {
        display: flex;
        flex-direction: column;
        max-width: 100px;
        gap: 10px;
        padding: 10px;
      }
    }

    .bling-auth {
      padding: 10px;
    }
  }
}