form {
    .MuiTextField-root:not(.phone-texfield) {
        margin: 0 0 15px 0;
    }

    .row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;

        #gender-label {
            top: -8px;
            left: 14px;
        }

        .MuiFormHelperText-root {
            margin-top: 4px;
            margin-left: 12px;
            color: red;
        }
    }

    .button-group {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        button {
            background-color: #56ccf2 !important;
            color: #FFFFFF;
            border-radius: 20px;
        }
    }
}